/* eslint no-useless-constructor:0 */
/* eslint camelcase: 0 */

export class ConfirmResetPasswordDTO {
  constructor(
    readonly new_password: string,
    readonly uid: string,
    readonly token: string,
  ) { }
}
