/* eslint no-useless-constructor:0 */

import { CartItem, CartItemDetails, CartMenuSetItem } from '@/model/Cart'
import { formatPrice } from '../CurrencyFormatter'

export class CartItemDTO {
  constructor(
    readonly itemId: string,
    readonly count: number,
    readonly contentItemsId: string[] // For menuSet
  ) { }
}

export class CartItemContentDTO {
  constructor(
    readonly itemId: string,
    readonly count: number,
    readonly price: number,
    readonly title: string
  ) { }
}

export function cartItemsToDto(cartItems: CartItem[]): CartItemDTO[] {
  return cartItems.map(
    item => {
      let contentItemsId = []
      if (item instanceof CartMenuSetItem) {
        contentItemsId = item.contentItemsId
      }
      return new CartItemDTO(item.itemId, item.count, contentItemsId)
    }
  )
}

export function mapToCartItemDetails(cartItems: CartItemContentDTO[], locale: string): CartItemDetails[] {
  return cartItems.map(item => new CartItemDetails(item.itemId, item.count, item.title, formatPrice(item.price, locale), formatPrice(item.price * item.count, locale)))
}
