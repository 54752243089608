import { formatPrice } from '@/data/CurrencyFormatter'
import { Cart, CartMenuSetItem, CartSingleItem } from '@/model/Cart'
import { MenuModel } from '@/model/Menu'
import { MenuCartItem } from '@/model/MenuCartItem'

export class MenuCartItemsService {
  getMenuCartItems(cart: Cart, menu: MenuModel): MenuCartItem[] {
    return cart.items.map(cartItem => {
      if (cartItem instanceof CartSingleItem) {
        const menuItemModel = menu.getItemById(cartItem.itemId)
        if (!menuItemModel) {
          return null
        }
        const itemTotalPrice = menuItemModel.price * cartItem.count
        const formattedItemTotalPrice = formatPrice(itemTotalPrice, menu.business.locale)
        return new MenuCartItem(cartItem, menuItemModel.title, formattedItemTotalPrice)
      } else if (cartItem instanceof CartMenuSetItem) {
        const menuSetItem = menu.getMenuSetItem(cartItem.itemId)
        if (!menuSetItem) {
          return null
        }
        const totalPrice = menuSetItem.getTotalPrice(cartItem.contentItemsId)
        const itemTotalPrice = totalPrice * cartItem.count
        const formattedItemTotalPrice = formatPrice(itemTotalPrice, menu.business.locale)
        const contentLabel = menuSetItem.getMenuSetItems(cartItem.contentItemsId).map(it => it.productName).join(', ')
        return new MenuCartItem(cartItem, `${menuSetItem.title}: ${contentLabel}`, formattedItemTotalPrice)
      }
      return null
    })
      .filter(item => item)
  }

  getCartTotalFormatted(cart: Cart, menu: MenuModel): string {
    const total = cart.items.reduce((sum, cartItem) => {
      let price = 0
      if (cartItem instanceof CartSingleItem) {
        price = menu.getItemById(cartItem.itemId).price
      } else if (cartItem instanceof CartMenuSetItem) {
        price = menu.getMenuSetItem(cartItem.itemId).getTotalPrice(cartItem.contentItemsId)
      }
      return (price * cartItem.count) + sum
    }, 0)
    return formatPrice(total, menu.business.locale)
  }
}
