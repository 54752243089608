
export function areListsEqualIgnoreOrder(list1: string[], list2: string[]): boolean {
  if (list1.length !== list2.length) {
    return false
  }
  if (list1 === list2) {
    return true
  }

  const sortedList1 = list1.slice().sort()
  const sortedList2 = list2.slice().sort()

  for (let i = 0; i < sortedList1.length; i++) {
    if (sortedList1[i] !== sortedList2[i]) {
      return false
    }
  }

  return true
}
