import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"100%"},
  class: "pl-3 pr-3"
}
const _hoisted_2 = {
  align: "center",
  justify: "center",
  "align-self": "center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CentralLayout = _resolveComponent("CentralLayout")!

  return (_openBlock(), _createBlock(_component_CentralLayout, null, {
    "top-left": _withCtx(() => [
      _renderSlot(_ctx.$slots, "top-left")
    ]),
    default: _withCtx(() => [
      (!_ctx.done)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_alert, {
              align: "left",
              icon: "mdi-alert-circle-outline",
              text: _ctx.errorMessage,
              type: "error",
              variant: "tonal",
              class: _normalizeClass(["mb-10 mt-5", { invisible: !_ctx.errorMessage }])
            }, null, 8, ["text", "class"]),
            _renderSlot(_ctx.$slots, "default")
          ]))
        : (_openBlock(), _createBlock(_component_v_col, {
            key: 1,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "done", {}, () => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_img, {
                    alt: "Vue logo",
                    src: require(`@/assets/img_not_found.png`),
                    width: 150,
                    height: 150
                  }, null, 8, ["src"])
                ]),
                _createVNode(_component_v_card_title, { class: "ext-h5 font-weight-bold pt-8" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('you_are_authentifed')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 3
          }))
    ]),
    _: 3
  }))
}