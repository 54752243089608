import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "pt-15"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "text-left"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "text-title-1 font-weight-bold" }
const _hoisted_7 = { class: "mt-5" }
const _hoisted_8 = { class: "text-title-1 font-weight-bold" }
const _hoisted_9 = { class: "mt-5" }
const _hoisted_10 = { class: "text-title-1 font-weight-bold" }
const _hoisted_11 = { class: "align-self-center" }
const _hoisted_12 = { class: "mt-5 text-left" }
const _hoisted_13 = { class: "text-title-1 font-weight-bold" }
const _hoisted_14 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_AccountAccessView = _resolveComponent("AccountAccessView")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_ErrorStateView = _resolveComponent("ErrorStateView")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.menu)
      ? (_openBlock(), _createBlock(_component_v_app_bar, {
          key: 0,
          dark: "",
          flat: "",
          color: "blue-grey-darken-4",
          align: "left",
          class: "pr-2"
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_btn, {
              icon: "mdi-home",
              onClick: _ctx.goToHome
            }, null, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, { class: "font-weight-black" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.menu.business.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_AccountAccessView)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.hasData())
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.errorCheckSession)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ErrorStateView, {
                    message: _ctx.$t('error_message'),
                    showTryAgain: true,
                    onOnTryAgain: _ctx.checkSession
                  }, null, 8, ["message", "onOnTryAgain"])
                ]))
              : (_ctx.menu.business.isOpened)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h1", {
                      class: "text-left",
                      innerHTML: _ctx.$t('checkout_review_order', { name: _ctx.menu.business.title })
                    }, null, 8, _hoisted_3),
                    (_ctx.menu.business.displayEta)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-timer-outline",
                            color: "grey",
                            size: "xsmall",
                            class: "mr-1"
                          }),
                          _createElementVNode("span", {
                            innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.processingTimeMinutes })
                          }, null, 8, _hoisted_5)
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_overlay, {
                      "model-value": _ctx.isLoading(),
                      class: "align-center justify-center",
                      "close-on-back": false,
                      persistent: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_progress_circular, {
                          color: "secondary",
                          indeterminate: "",
                          size: "64"
                        })
                      ]),
                      _: 1
                    }, 8, ["model-value"]),
                    _createVNode(_component_v_snackbar, {
                      modelValue: _ctx.displayErrorMessage,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayErrorMessage) = $event)),
                      timeout: 5000,
                      color: "primary",
                      rounded: "pill"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          icon: "mdi-alert-box",
                          class: "mr-1",
                          color: "error_light"
                        }),
                        _createTextVNode(_toDisplayString(_ctx.$t('checkout_submit_error')), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_v_form, {
                      modelValue: _ctx.valid,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valid) = $event)),
                      ref: "form"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "mt-5" }, {
                          default: _withCtx(() => [
                            (!_ctx.menu.business.requiresLoginToOrder)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  class: "text-left",
                                  cols: "12",
                                  md: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('checkout_input_details_label')), 1),
                                      _createVNode(_component_v_card, { class: "rounded-xl" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, {
                                            modelValue: _ctx.nameInput,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nameInput) = $event)),
                                            label: _ctx.$t("checkout_input_name_label"),
                                            rules: _ctx.nameRules(),
                                            counter: 10,
                                            required: "",
                                            class: "ml-3 mr-3 mt-3 mb-1"
                                          }, null, 8, ["modelValue", "label", "rules"]),
                                          (_ctx.menu.business.askClientPhoneNumber)
                                            ? (_openBlock(), _createBlock(_component_v_text_field, {
                                                key: 0,
                                                modelValue: _ctx.phoneNumberInput,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phoneNumberInput) = $event)),
                                                rules: _ctx.phoneNumberRules(),
                                                label: _ctx.$t("checkout_input_phone_number_label"),
                                                class: "ml-3 mr-3 mb-3",
                                                required: "",
                                                "hide-details": "auto"
                                              }, null, 8, ["modelValue", "rules", "label"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('checkout_input_comment_label')), 1),
                                      _createVNode(_component_v_card, { class: "rounded-xl" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_textarea, {
                                            label: _ctx.$t('checkout_input_comment_hint'),
                                            modelValue: _ctx.commentsInput,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.commentsInput) = $event)),
                                            rules: _ctx.commentsRules(),
                                            class: "ml-3 mr-3 mt-3 mb-1",
                                            rows: "2",
                                            "auto-grow": ""
                                          }, null, 8, ["label", "modelValue", "rules"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_9, [
                                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('checkout_establishment_address_label')), 1),
                                      _createVNode(_component_v_card, { class: "rounded-xl" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_row, {
                                            class: "align-self-center pa-3",
                                            align: "center",
                                            justify: "space-between"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, { cols: "auto" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_icon, {
                                                    icon: "mdi-map-marker-outline",
                                                    class: "mr-1"
                                                  }),
                                                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.menu.business.address), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_col, { cols: "auto" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_btn, {
                                                    variant: "plain",
                                                    density: "comfortable",
                                                    color: "blue-darken-3",
                                                    href: _ctx.menu.business.addressUrl,
                                                    target: "_blank"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('checkout_establishment_address_itinerary')), 1),
                                                      _createVNode(_component_v_icon, {
                                                        icon: "mdi-chevron-right",
                                                        class: "mr-1"
                                                      })
                                                    ]),
                                                    _: 1
                                                  }, 8, ["href"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('basket')), 1),
                                  _createVNode(_component_v_card, { class: "pa-4 rounded-xl" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { class: "text-left text-subtitle-1 font-weight-regular" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems(), (item) => {
                                            return (_openBlock(), _createBlock(_component_v_row, {
                                              key: item.cartItem.itemId,
                                              class: "pt-1 pb-1 pl-0 pr-0"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("span", null, _toDisplayString(item.cartItem.count) + "x ", 1),
                                                    _createElementVNode("span", null, _toDisplayString(item.label), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.itemTotalPrice), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128)),
                                          _createVNode(_component_v_divider, { class: "mt-5 mb-5" }),
                                          _createVNode(_component_v_row, { class: "pb-1 pl-0 pr-0" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t("total")), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.totalCart()), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, { class: "mt-5" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  variant: "flat",
                                  block: "",
                                  class: "mt-2",
                                  onClick: _ctx.submit,
                                  color: "primary",
                                  disabled: _ctx.isLoading()
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('checkout_submit_order')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_ErrorStateView, {
                      message: _ctx.$t('closed_message')
                    }, null, 8, ["message"])
                  ]))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}