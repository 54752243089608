
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuItemModel, MenuModel } from '@/model/Menu'
import { MenuCartItem } from '@/model/MenuCartItem'
import { MenuCartItemsService } from '@/service/MenuCartItemsService'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    cart: Cart,
    stickyCheckoutBtn: Boolean,
    menu: MenuModel,
    menuModel: MenuModel
  }
})
export default class CartView extends Vue {
  cart: Cart
  menu: MenuModel
  stickyCheckoutBtn: boolean
  menuCartItemsService = new MenuCartItemsService()

  cartItems(): MenuCartItem[] {
    return this.menuCartItemsService.getMenuCartItems(this.cart, this.menu)
  }

  totalCart(): string {
    return this.menuCartItemsService.getCartTotalFormatted(this.cart, this.menu)
  }

  countCart(itemId: string): number {
    return this.cart.getItem(itemId)?.count || 0
  }

  onAddToCart(itemId: string): void {
    this.$store.dispatch('addToCart', new CartSingleItem(itemId, 1))
  }

  onMinusFromCart(itemId: string): void {
    this.$store.dispatch('minusFromCart', itemId)
  }
}
