

import { formatPrice } from '@/data/CurrencyFormatter'
import { Cart, CartMenuSetItem } from '@/model/Cart'
import { MenuSet, MenuSetItem } from '@/model/Menu'
import { v4 as uuidv4 } from 'uuid'
import { Options, Vue } from 'vue-class-component'
import MenuSetMultiChoiceListView from './MenuSetMultiChoiceListView.vue'
import MenuSetSingleChoiceListView from './MenuSetSingleChoiceListView.vue'

@Options({
  props: {
    menuSet: MenuSet,
    cart: Cart,
    locale: String
  },
  components: { MenuSetSingleChoiceListView, MenuSetMultiChoiceListView }
})
export default class MenuSetDetailsView extends Vue {
  menuSet!: MenuSet
  cart?: Cart
  locale: string
  count = 1

  selectedItemsIndex?: MenuSetItem[][] = [[]]

  created(): void {
    this.menuSet.categories.forEach((section, index) => {
      this.selectedItemsIndex[index] = new Array(section.maxSelection).fill(null)
    })
  }

  get allItemSelected(): boolean {
    return !this.selectedItemsIndex.some((selected, index) => {
      if (selected.filter(item => item != null).length < this.menuSet.categories[index].minSelection) {
        return true
      }
      return false
    })
  }

  onValidate() {
    const itemsId = this.menuContent.map(i => i.id)
    const cartItems = new CartMenuSetItem(uuidv4(), this.menuSet.id, this.count, itemsId)
    this.$store.dispatch('addToCart', cartItems)
    this.onDismiss()
  }

  get totalPrice(): string {
    const price = this.menuContent.reduce((sum, item) => item.extraPrice + sum, this.menuSet.initialPrice)
    return formatPrice(price, this.locale)
  }

  get menuContent(): MenuSetItem[] {
    return this.selectedItemsIndex.flatMap(i => i.filter(j => j))
  }

  onDismiss(): void {
    this.$emit('onDismiss')
  }
}
