
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { userRepository } from '@/data/UserRepository'
import { isEmailValid, isNameValid, isPasswordStrong } from '@/form/rules'
import { SignupEmailExistError } from '@/model/SignupError'
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'

@Options({
  components: { ConnexionLayout }
})
export default class SignupView extends Vue {
  nameInput = ''
  emailInput = ''
  passwordInput = ''
  done = false
  valid = true
  isLoading = false
  errorMessage?: string = null

  nameRule() {
    if (!isNameValid(this.nameInput)) {
      return [this.$t('error_invalid_field')]
    }
    return [true]
  }

  emailRules() {
    if (!isEmailValid(this.emailInput)) {
      return [this.$t('error_invalid_email')]
    }
    return [true]
  }

  passwordRules() {
    if (!this.passwordInput) {
      return [this.$t('error_invalid_field')]
    }
    if (!isPasswordStrong(this.passwordInput)) {
      return [this.$t('error_week_password')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      const redirectUrl = this.$route.query.redirect_url as string
      userRepository.singup(this.nameInput, this.emailInput, this.passwordInput)
        .then(() => {
          this.isLoading = false
          this.$store.dispatch('fetchUserName')
          if (redirectUrl) {
            this.$router.replace(redirectUrl)
          }
          this.done = true
        })
        .catch((e) => {
          this.isLoading = false
          if (e instanceof SignupEmailExistError) {
            this.errorMessage = this.$t('error_email_already_exist')
          } else {
            this.errorMessage = this.$t('error_signup')
          }
        })
    }
  }
}
