import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  align: "center",
  justify: "center",
  "align-self": "center",
  class: "pt-10"
}
const _hoisted_2 = { class: "ext-h font-weight-bold pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ConnexionLayout = _resolveComponent("ConnexionLayout")!

  return (_openBlock(), _createBlock(_component_ConnexionLayout, {
    done: _ctx.done,
    errorMessage: _ctx.errorMessage
  }, {
    "top-left": _withCtx(() => [
      (_ctx.canGoBack)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            icon: "mdi-close",
            variant: "plain",
            onClick: _ctx.goBack,
            class: "ma-3"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    done: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, {
          icon: "mdi-check-circle",
          size: "100",
          color: "success"
        })
      ]),
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('account_reset_password_done')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
        ref: "form",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
        autocomplete: "on",
        "validate-on": "submit",
        class: "mt-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.emailInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailInput) = $event)),
            label: _ctx.$t("account_email"),
            type: "email",
            class: "mb-1",
            variant: "outlined",
            color: "blue-grey-darken-4",
            "base-color": "blue-grey-darken-4",
            autocomplete: "email",
            rules: _ctx.emailRules()
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-email-outline",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_v_btn, {
            variant: "flat",
            block: "",
            class: "mt-2",
            type: "submit",
            color: "primary",
            loading: _ctx.isLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('next')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["done", "errorMessage"]))
}