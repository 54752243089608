
import { userRepository } from '@/data/UserRepository'
import { Options, Vue } from 'vue-class-component'
import { RouteParamsRaw, useRoute } from 'vue-router'

@Options({})
export default class AccountAccessView extends Vue {
  showDialog = false
  get userName(): string | null {
    return this.$store.state.activeUserName
  }

  get initial(): string | null {
    if (!this.$store.state.activeUserName) {
      return ''
    }
    return this.$store.state.activeUserName.substring(0, 1)
  }

  get allOrdersParam(): RouteParamsRaw {
    const route = useRoute()
    return {
      slug: route.params.slug,
    }
  }

  mounted(): void {
    this.$store.dispatch('fetchUserName')
  }

  logout(): void {
    this.showDialog = false
    userRepository.logout()
    this.$store.dispatch('fetchUserName')
  }
}
