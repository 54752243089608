import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (!_ctx.cart.isEmpty())
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "rounded-xl mx-auto",
        color: "primary",
        dark: true,
        elevation: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "pa-3 space-between" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                class: "text-subtitle-1 font-weight-bold",
                cols: "auto"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cart.getCountItems()), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "align-self-center text-subtitle-1 font-weight-bold" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('cart_view')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                class: "text-subtitle-1 font-weight-bold",
                cols: "auto"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.totalCart()), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}