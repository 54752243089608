
export function isEmailValid(email?: string): boolean {
  if (!email) {
    return false
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export function isPasswordValid(password?: string): boolean {
  if (!password) {
    return false
  }
  return password.length > 4
}

export function isPasswordStrong(password?: string): boolean {
  return password.length > 5 && countUniqueChars(password) > 5
}

export function isNameValid(name?: string): boolean {
  if (!name) {
    return false
  }
  return name.length >= 2
}

function countUniqueChars(password: string): number {
  const uniqueChars = new Set(password)
  return uniqueChars.size
}
