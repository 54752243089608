/* eslint no-useless-constructor:0 */

import { CartItem } from '@/model/Cart'

export class MenuCartItem {
  constructor(
    readonly cartItem: CartItem,
    readonly label: string,
    readonly itemTotalPrice: string
  ) { }
}
