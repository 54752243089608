
import { jwtDecode } from 'jwt-decode'

export function checkTokenValidity(token: string): boolean {
  const decodedToken = jwtDecode(token)
  if (!decodedToken.exp) {
    console.log('Token has invalid exp')
    return false
  }

  const currentTimeInSeconds = Math.floor(Date.now() / 1000)
  return decodedToken.exp > currentTimeInSeconds
}

export function getUserNameFromToken(token: string): string {
  const decodedToken = jwtDecode(token)
  return decodedToken['name']
}

export function getUserIdFromToken(token: string): string {
  const decodedToken = jwtDecode(token)
  return decodedToken['user_id']
}
