/* eslint no-useless-constructor:0 */

import { areListsEqualIgnoreOrder } from '@/utils/list'

export class Cart {
  constructor(
    readonly id: string,
    readonly items: CartItem[] = []
  ) { }

  public getCartItemOrEmpty(itemId: string): CartItem {
    const cartItem = this.getItem(itemId)
    if (cartItem != null) { return cartItem }
    const newCartItem = new CartSingleItem(itemId, 0)
    this.items.push(newCartItem)
    return newCartItem
  }

  public addToCart(item: CartItem) {
    if (item instanceof CartSingleItem) {
      const existingCartItem = this.getItem(item.itemId)
      if (existingCartItem) {
        existingCartItem.count += item.count
      } else {
        this.items.push(item)
      }
    } else if (item instanceof CartMenuSetItem) {
      const existingCartItem = this.getMenuSetItemByContent(item.itemId, (item as CartMenuSetItem).contentItemsId)
      if (existingCartItem) {
        existingCartItem.count += item.count
      } else {
        this.items.push(item)
      }
    }
  }

  public removeItem(itemId: string) {
    const index = this.items.findIndex((item) => item.itemId === itemId)
    if (index >= 0) {
      this.items.splice(index, 1)
    }
  }

  public removeItemById(id: string) {
    const index = this.items.findIndex((item) => item.id === id)
    if (index >= 0) {
      this.items.splice(index, 1)
    }
  }

  public getItem(itemId: string): CartItem | undefined {
    const cartItem = this.items.find((item) => item.itemId === itemId)
    return cartItem
  }

  private getMenuSetItemByContent(menuSetId: string, content: string[]): CartItem | null {
    return this.items.find((item) =>
      item instanceof CartMenuSetItem &&
      item.itemId === menuSetId &&
      areListsEqualIgnoreOrder(item.contentItemsId, content)
    )
  }

  public getCountItems(): number {
    return this.items.reduce((sum, cartItem) => {
      return cartItem.count + sum
    }, 0)
  }

  public isEmpty(): boolean {
    return this.items.length === 0
  }

  serialize(): any {
    return {
      id: this.id,
      items: this.items.map(item => item.serialize())
    }
  }

  static deserialize(data: any): Cart {
    const items = data.items.map((itemData: any) => CartItem.deserialize(itemData))
    return new Cart(data.id, items)
  }
}

export abstract class CartItem {
  constructor(public id: string, public itemId: string, public count: number) { }

  abstract serialize(): any

  static deserialize(data: any): CartItem {
    switch (data.type) {
      case 'CartSingleItem':
        return CartSingleItem.deserialize(data)
      case 'CartMenuSetItem':
        return CartMenuSetItem.deserialize(data)
      default:
        throw new Error(`Unknown CartItem type: ${data.type}`)
    }
  }
}

export class CartSingleItem extends CartItem {
  constructor(itemId: string, count: number) {
    super(itemId, itemId, count)
  }

  serialize(): any {
    return {
      type: 'CartSingleItem',
      itemId: this.itemId,
      count: this.count,
    }
  }

  static deserialize(data: any): CartSingleItem {
    return new CartSingleItem(data.itemId, data.count)
  }
}

export class CartMenuSetItem extends CartItem {
  constructor(public id: string, itemId: string, count: number, public contentItemsId: string[]) {
    super(id, itemId, count)
  }

  serialize() {
    return {
      type: 'CartMenuSetItem',
      id: this.id,
      itemId: this.itemId,
      count: this.count,
      contentItemsId: this.contentItemsId,
    }
  }

  static deserialize(data: any): CartMenuSetItem {
    return new CartMenuSetItem(data.id, data.itemId, data.count, data.contentItemsId)
  }
}

export class CartItemDetails {
  constructor(
    readonly itemId: string,
    readonly count: number,
    readonly title: string,
    readonly price: string,
    readonly totalPrice: string
  ) { }
}
