interface CacheItem<T> {
  value: T
  expiry: number
}

export class CacheData<T> {
  private cache: Record<string, CacheItem<T>> = {}
  private storageKey: string

  constructor(storageKey: string) {
    this.storageKey = storageKey
    const storedCache = localStorage.getItem(this.storageKey)
    if (storedCache) {
      this.cache = JSON.parse(storedCache)
    }
  }

  set(key: string, value: T, ttlSeconds: number): void {
    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + ttlSeconds)
    const expiry = expiryDate.getTime()
    this.cache[key] = { value, expiry }
    localStorage.setItem(this.storageKey, JSON.stringify(this.cache))
  }

  get(key: string): T | null {
    const item = this.cache[key]
    if (!item || item.expiry < Date.now()) {
      delete this.cache[key]
      localStorage.setItem(this.storageKey, JSON.stringify(this.cache))
      return null
    }
    return item.value as T
  }

  remove() {
    this.cache = {}
    localStorage.removeItem(this.storageKey)
  }
}
