

import { Options, Vue } from 'vue-class-component'
import { MenuSet } from '@/model/Menu'
import { Cart } from '@/model/Cart'
import MenuSetDetailsView from './MenuSetDetailsView.vue'
import { useDisplay } from 'vuetify'

@Options({
  props: {
    menuSet: MenuSet,
    cart: Cart,
    locale: String
  },
  components: { MenuSetDetailsView }
})
export default class MenuSetView extends Vue {
  menuSet!: MenuSet
  cart?: Cart
  showDetailsDialog = false
  locale: string

  onAddToCart() {
    this.showDetailsDialog = true
  }

  get showDialogAsFullScreen(): boolean {
    const { smAndDown } = useDisplay()
    return smAndDown.value
  }
}
