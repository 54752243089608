// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VBottomSheet } from 'vuetify/labs/VBottomSheet'

import { aliases, mdi } from 'vuetify/iconsets/mdi'

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify'
import { Vue } from 'vue-class-component'

const appTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f9fafa',
    surface: '#FFFFFF',
    primary: '#546E7A',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    disabled: '#757575',
    'secondary-darken-1': '#018786',
    error_light: '#ef9a9a',
    error: '#E53935',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'card-color': '#FB8C00',
    '$card-color': '#FB8C00',
    'blue-grey-darken-4': '#263238',
    'grey-darken-1': '#757575',
    'on-surface': '#000'
  }
}

const cardTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    primary: '#1976D2',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
}

export default createVuetify(
  {
    theme: {
      defaultTheme: 'appTheme',
      themes: {
        appTheme: appTheme,
        cardTheme: cardTheme
      }
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    components: {
      VBottomSheet
    }
  }
)

export type VForm = Vue & { validate: () => boolean }

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
