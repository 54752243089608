import { getFirebaseApp } from '@/data/firebase'
import { getMessaging, getToken, onMessage } from '@firebase/messaging'

const vapidKey = 'BNuf1apqiS9wwpuRx_IwgPy5tQq14xPzVKM70MN6ZYe6ShX27GsO01YAiD7oxClLfT8VHZB-XxiDOH-OXU1hn0c'
const swMessagesChannel = new BroadcastChannel('sw-messages')

export function addOnNotificationReceived(callback: () => void) {
  const messaging = getMessaging(getFirebaseApp())
  onMessage(messaging, (payload) => {
    callback()
  })
}

export function addOnBackgroundNotificationReceived(callback: () => void) {
  swMessagesChannel.addEventListener('message', (event) => {
    callback()
  })
}

export function getNotificationToken(): Promise<string> {
  const messaging = getMessaging(getFirebaseApp())
  return getToken(messaging, { vapidKey: vapidKey })
}
