const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/

export function validatePhoneNumber(phoneNumber: string, locale: string) {
  return phoneNumber.length >= 8 && countDifferentChars(phoneNumber) > 2 && phoneNumberRegex.test(phoneNumber) && validateByLocale(phoneNumber, locale)
}

function validateByLocale(phoneNumber: string, locale: string): boolean {
  if (/(-|_)TN$/.test(locale)) {
    const cleanedForLocale = phoneNumber.replace(/^\+216/, '').replace(/^00216/, '')
    if (cleanedForLocale.startsWith('+') || cleanedForLocale.startsWith('00')) {
      return true
    }
    return cleanedForLocale.length === 8
  }
  return true
}

function countDifferentChars(str: string): number {
  const chars = new Set(str)
  return chars.size
}
