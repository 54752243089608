import { FirebaseApp, initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyB-fyvQFvPKfqgxhSVnAZcW8l0AbZ_ue18',
  authDomain: 'kmandi-28e19.firebaseapp.com',
  projectId: 'kmandi-28e19',
  storageBucket: 'kmandi-28e19.appspot.com',
  messagingSenderId: '816891011702',
  appId: '1:816891011702:web:3169adaf092ab8377c7557',
  measurementId: 'G-38TF5RV4PF'
}

let firebaseApp: FirebaseApp = null

export function getFirebaseApp(): FirebaseApp {
  if (firebaseApp == null) {
    firebaseApp = initializeApp(firebaseConfig)
  }
  return firebaseApp
}
