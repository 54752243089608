/* eslint camelcase: 0 */

export class SignupRequestDTO {
  constructor(
    readonly first_name: string,
    readonly email: string,
    readonly password: string,
    readonly captcha_token: string | null,
  ) { }
}

export class SignupAnonymousUserequestDTO {
  constructor(
    readonly captcha_token: string | null,
  ) { }
}
