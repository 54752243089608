import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { ref: "menu_container" }
const _hoisted_2 = {
  key: "menu_sets_section",
  id: "menu_sets_section",
  ref: "menu_sets_section",
  title: "Menu sets",
  style: {"scroll-margin":"20px"}
}
const _hoisted_3 = { class: "text-left pt-8 pb-2" }
const _hoisted_4 = ["id", "title"]
const _hoisted_5 = { class: "text-left pt-8 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSetView = _resolveComponent("MenuSetView")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_MenuItemView = _resolveComponent("MenuItemView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.menu.menuSets.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('section_menu_sets')), 1),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.menuSets, (menuSetItem) => {
                  return _renderSlot(_ctx.$slots, "default", {
                    key: menuSetItem.id
                  }, () => [
                    _createVNode(_component_MenuSetView, {
                      menuSet: menuSetItem,
                      cart: _ctx.cart,
                      locale: _ctx.menu.business.locale
                    }, null, 8, ["menuSet", "cart", "locale"])
                  ])
                }), 128))
              ]),
              _: 3
            })
          ], 512))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.content, (section) => {
        return (_openBlock(), _createElementBlock("div", {
          key: section.id,
          id: section.id,
          ref_for: true,
          ref: section.id,
          title: section.title,
          style: {"scroll-margin":"20px"}
        }, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(section.title), 1),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.content, (item) => {
                return _renderSlot(_ctx.$slots, "default", {
                  key: item.id
                }, () => [
                  _createVNode(_component_MenuItemView, {
                    item: item,
                    cart: _ctx.cart,
                    displayStyle: _ctx.menu.business.menuDisplayStyle
                  }, null, 8, ["item", "cart", "displayStyle"])
                ])
              }), 128))
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_4))
      }), 128))
    ], 512)
  ]))
}