
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { userRepository } from '@/data/UserRepository'
import { isPasswordValid } from '@/form/rules'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { VForm } from 'vuetify/lib/components/index.mjs'

@Options({
  components: { ConnexionLayout }
})
export default class ConfirmResetPasswordView extends Vue {
  newPasswordInput = ''
  confirmNewPasswordInput = ''
  valid = false
  done = false
  isLoading = false
  errorMessage?: string = null

  passwordRules() {
    const { t } = useI18n()
    if (!isPasswordValid(this.newPasswordInput)) {
      return [t('error_field_required')]
    }
    return [true]
  }

  confirmNewpasswordRules() {
    const { t } = useI18n()
    if (this.confirmNewPasswordInput !== this.newPasswordInput) {
      return [t('account_passwords_not_match')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      const userId = this.$route.params.u_id as string
      const token = this.$route.params.token as string

      this.isLoading = true
      this.errorMessage = null
      const url = this.$route.query.next as string

      userRepository.confirmResetPassword(this.confirmNewPasswordInput, userId, token)
        .then(() => {
          this.done = true
          this.redirectWithDelay(url)
        })
        .catch((e) => {
          this.errorMessage = this.$t('error_message')
          this.isLoading = false
        })
    }
  }

  redirectWithDelay(redirectUrl: string) {
    if (redirectUrl) {
      this.$router.replace(redirectUrl)
    }
  }
}
