export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export async function withRetry<T>(promiseFunction: () => Promise<T>, maxRetries: number, delayRetryMs: number): Promise<T> {
  let retries = 0
  while (true) {
    try {
      const result = await promiseFunction()
      return result
    } catch (error) {
      console.log(`Attempts ${retries}`, error)
      if (retries >= maxRetries) {
        throw error
      }
      retries++
      await new Promise(resolve => setTimeout(resolve, delayRetryMs))
    }
  }
}
