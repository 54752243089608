import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { width: "100%" }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = { style: {"white-space":"normal"} }
const _hoisted_4 = {
  key: 0,
  class: "text-left",
  style: {"white-space":"pre-line"}
}
const _hoisted_5 = { class: "text-body-1" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { style: {"white-space":"normal"} }
const _hoisted_9 = {
  key: 0,
  class: "pa-0 mt-4",
  style: {"white-space":"pre-line"}
}
const _hoisted_10 = { class: "text-body-1" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.mobileDisplay)
        ? (_openBlock(), _createBlock(_component_v_img, {
            key: 0,
            width: "100%",
            "max-height": "300",
            "aspect-ratio": "1/1",
            cover: "",
            src: _ctx.business.image
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (_ctx.mobileDisplay)
        ? (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "align-center pt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.business.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "auto",
                    class: "d-flex align-center"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "top-right")
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }),
              _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left mb-0 pb-0 mt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    href: _ctx.business.addressUrl,
                    target: "_blank",
                    variant: "text",
                    color: "blue-darken-3",
                    class: "text-left"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-map-marker-outline pr-4",
                        size: "xsmall"
                      }),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.business.address), 1),
                      _createVNode(_component_v_icon, {
                        icon: "mdi-chevron-right",
                        class: "ml-1"
                      })
                    ]),
                    _: 1
                  }, 8, ["href"])
                ]),
                _: 1
              }),
              (_ctx.business.description)
                ? (_openBlock(), _createElementBlock("blockquote", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.business.description), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.business.displayEta)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left b-0 pb-0" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.processingTimeMinutes })
                        }, null, 8, _hoisted_7)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }))
        : (_openBlock(), _createBlock(_component_v_row, {
            key: 2,
            justify: "space-between"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "3",
                class: "pr-12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    width: "100%",
                    height: "100%",
                    "max-height": "300",
                    "aspect-ratio": "1/1",
                    cover: "",
                    src: _ctx.business.image
                  }, null, 8, ["src"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "7",
                class: "text-left pt-12 pl-0 mb-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "text-h4 pa-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.business.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left pl-0 mb-0 pb-0 mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        href: _ctx.business.addressUrl,
                        target: "_blank",
                        variant: "plain",
                        color: "blue-darken-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-map-marker-outline pr-4",
                            size: "xsmall"
                          }),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.business.address), 1),
                          _createVNode(_component_v_icon, {
                            icon: "mdi-chevron-right",
                            class: "ml-1"
                          })
                        ]),
                        _: 1
                      }, 8, ["href"])
                    ]),
                    _: 1
                  }),
                  (_ctx.business.description)
                    ? (_openBlock(), _createElementBlock("blockquote", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.business.description), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.business.displayEta)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        _createVNode(_component_v_card_text, { class: "text-body-2 mt-2 pa-0" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.processingTimeMinutes })
                            }, null, 8, _hoisted_12)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "auto",
                class: "pt-12 mr-3"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "top-right")
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
    ])
  ]))
}