/* eslint no-useless-constructor:0 */

export class LoginRequestDTO {
  constructor(
    readonly username: string,
    readonly password: string,
  ) { }
}

export class LoginResponseDTO {
  constructor(
    readonly access: string,
    readonly refresh?: string,
  ) { }
}
