

import { MenuSetItem } from '@/model/Menu'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuSetMultiChoiceListView',
  props: {
    menuSetItems: {
      type: Array as PropType<MenuSetItem[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<MenuSetItem[] | null>,
      required: true,
    },
    minSelection: {
      type: Number,
      required: true,
    },
    maxSelection: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedItem: this.modelValue,
    }
  },
  computed: {
    countSelection() {
      return this.selectedItem.filter(i => i).length
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedItem = newVal
    },
    selectedItem(newVal) {
      this.$emit('update:modelValue', newVal)
    },
  },
})
