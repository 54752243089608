
const map = new Map<RegExp, Intl.NumberFormatOptions>([
  [/(-|_)TN$/, {
    style: 'currency',
    currency: 'TND',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  }],
  [/(-|_)FR$/, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }],
])

function formatWithOptions(price: number, locale: string, options: Intl.NumberFormatOptions): string {
  const formatter = new Intl.NumberFormat(locale, options)
  return formatter.format(price)
}

export function formatPrice(price: number, locale: string): string {
  for (const [key, value] of map.entries()) {
    if (key.test(locale)) {
      try {
        return formatWithOptions(price, locale, value)
      } catch (e: any) {
        console.log(e)
      }
    }
  }
  return price.toString()
}
