import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "rounded-t-lg" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
        return (_openBlock(), _createElementBlock("div", {
          key: order.id,
          class: "pl-4 pr-4"
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'order', params: { order_id: order.uuid } },
            custom: ""
          }, {
            default: _withCtx(({ navigate }) => [
              _createVNode(_component_v_card, {
                class: "pa-4 ma-4 rounded-xl",
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    innerHTML: _ctx.orderStatus(order)
                  }, null, 8, _hoisted_1),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    color: "black"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.cart, (item) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          key: item.itemId,
                          class: "pt-1 pb-1 pl-0 pr-0",
                          justify: "space-between"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(item.count) + "x ", 1),
                                _createElementVNode("span", null, _toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.totalPrice), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_v_row, {
                        class: "pb-1 font-weight-bold mt-3",
                        justify: "space-between"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('total')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right strong" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(order.totalPrice), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}