import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "mt-10" }
const _hoisted_3 = { class: "text-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ConnexionLayout = _resolveComponent("ConnexionLayout")!

  return (_openBlock(), _createBlock(_component_ConnexionLayout, {
    done: _ctx.done,
    errorMessage: _ctx.errorMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
        ref: "form",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
        autocomplete: "on",
        "validate-on": "submit",
        class: "mt-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.emailInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailInput) = $event)),
            label: _ctx.$t("account_email"),
            type: "email",
            class: "mb-1",
            variant: "outlined",
            color: "blue-grey-darken-4",
            "base-color": "blue-grey-darken-4",
            autocomplete: "email",
            rules: _ctx.emailRules()
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-email-outline",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.passwordInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordInput) = $event)),
            class: "mb-1",
            label: _ctx.$t("account_password"),
            type: _ctx.passwordVisible ? 'text' : 'password',
            variant: "outlined",
            color: "blue-grey-darken-4",
            "base-color": "blue-grey-darken-4",
            autocomplete: "current-password",
            rules: _ctx.passwordRules(),
            "onClick:appendInner": _cache[2] || (_cache[2] = ($event: any) => (_ctx.passwordVisible = !_ctx.passwordVisible))
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-lock-outline",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "type", "rules"]),
          _createVNode(_component_v_btn, {
            variant: "flat",
            block: "",
            class: "mt-2",
            type: "submit",
            color: "primary",
            loading: _ctx.isLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('log_in')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          variant: "outlined",
          block: "",
          class: "mt-2",
          type: "submit",
          color: "primary",
          disabled: _ctx.isLoading,
          to: { name: 'forgot-password', query: this.$route.query }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('forgot_password')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "to"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('account_no_account')) + " ", 1),
          _createVNode(_component_router_link, {
            class: "link",
            to: { name: 'signup', query: this.$route.query }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('sign_up')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["done", "errorMessage"]))
}