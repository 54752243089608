import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-body-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-body-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuSetItems, (item, index) => {
    return _withDirectives((_openBlock(), _createBlock(_component_v_checkbox, {
      key: item.id,
      value: item,
      modelValue: _ctx.selectedItem[index],
      "onUpdate:modelValue": ($event: any) => ((_ctx.selectedItem[index]) = $event),
      disabled: !_ctx.selectedItem[index] && _ctx.countSelection >= _ctx.maxSelection,
      "hide-details": "",
      class: "justify-start",
      dense: ""
    }, {
      label: _withCtx(() => [
        _createVNode(_component_v_row, {
          justify: "space-between",
          class: "w-100 pl-5 pr-5 font-weight-medium pt-0 pb-0 mt-0 mb-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.productName), 1),
            (item.extraPrice > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "+" + _toDisplayString(item.extraPriceFormatted), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["value", "modelValue", "onUpdate:modelValue", "disabled"])), [
      [_directive_ripple]
    ])
  }), 128))
}