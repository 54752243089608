
import { formatDuration } from '@/formatter/DateFormatter'
import { BusinessModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useDisplay } from 'vuetify'

@Options({
  props: {
    business: BusinessModel
  }
})
export default class BusinessView extends Vue {
  business: BusinessModel

  get processingTimeMinutes(): string {
    return formatDuration(this.business.processingTimeMinutes)
  }

  get mobileDisplay(): boolean {
    const { smAndDown } = useDisplay()
    return smAndDown.value
  }
}
