
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    item: MenuItemModel,
    countOnCart: Number,
    showImage: Boolean
  }
})
export default class MenuItemCompactView extends Vue {
  item!: MenuItemModel
  countOnCart!: number
  showImage!: boolean

  onAddToCart(): void {
    this.$emit('onAddToCart')
  }

  onMinusFromCart(): void {
    this.$emit('onMinusFromCart')
  }
}
