
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    item: MenuItemModel,
    countOnCart: Number
  }
})
export default class MenuItemStandardView extends Vue {
  item!: MenuItemModel
  countOnCart!: number

  onAddToCart(): void {
    this.$emit('onAddToCart')
  }

  onMinusFromCart(): void {
    this.$emit('onMinusFromCart')
  }
}
