import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "pl-10 pr-10",
  style: {"position":"relative"}
}
const _hoisted_2 = { class: "sticky-bottom bg-background py-2 px-5" }
const _hoisted_3 = {
  class: "align-self-center mr-5 ml-5 text-h6",
  color: "blue-grey-darken-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_MenuSetMultiChoiceListView = _resolveComponent("MenuSetMultiChoiceListView")!
  const _component_MenuSetSingleChoiceListView = _resolveComponent("MenuSetSingleChoiceListView")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "rounded-xl pt-2",
    elevation: "22",
    width: "100%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.menuSet.imageUrl)
          ? (_openBlock(), _createBlock(_component_v_img, {
              key: 0,
              width: "100%",
              "max-height": "400",
              "aspect-ratio": "1/1",
              cover: "",
              src: _ctx.menuSet.imageUrl
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, {
          icon: "mdi-close",
          class: "sticky-top ma-2 z-index fab raised rounded-pill",
          color: "blue-grey-darken-4",
          density: "comfortable",
          onClick: _ctx.onDismiss
        }, null, 8, ["onClick"])
      ]),
      _createVNode(_component_v_card_title, { class: "pt-15 pb-0 justify-start" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.menuSet.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { class: "pl-10 pr-10" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuSet.categories, (section, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: section.id,
              class: "pt-4"
            }, [
              _createVNode(_component_v_row, { class: "text-left text-h6" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(section.title), 1)
                ]),
                _: 2
              }, 1024),
              (section.minSelection > 0)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "text-subtitle-2 pb-3 text-disabled",
                    "text-color": "red"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Obligatoire")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (section.maxSelection > 1)
                ? (_openBlock(), _createBlock(_component_MenuSetMultiChoiceListView, {
                    key: 1,
                    menuSetItems: section.possibleItems,
                    modelValue: _ctx.selectedItemsIndex[index],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.selectedItemsIndex[index]) = $event),
                    minSelection: section.minSelection,
                    maxSelection: section.maxSelection
                  }, null, 8, ["menuSetItems", "modelValue", "onUpdate:modelValue", "minSelection", "maxSelection"]))
                : (_openBlock(), _createBlock(_component_MenuSetSingleChoiceListView, {
                    key: 2,
                    menuSetItems: section.possibleItems,
                    modelValue: _ctx.selectedItemsIndex[index][0],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.selectedItemsIndex[index][0]) = $event)
                  }, null, 8, ["menuSetItems", "modelValue", "onUpdate:modelValue"]))
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_v_btn, {
            icon: "mdi-minus",
            class: "z-index fab",
            color: "blue-grey-darken-4",
            disabled: _ctx.count <= 1,
            size: "small",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.count--), ["stop"]))
          }, null, 8, ["disabled"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.count), 1),
          _createVNode(_component_v_btn, {
            icon: "mdi-plus",
            class: "z-index fab",
            color: "blue-grey-darken-4",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.count++), ["stop"])),
            size: "small"
          })
        ]),
        _createVNode(_component_v_btn, {
          onClick: _ctx.onValidate,
          variant: "flat",
          block: "",
          color: "primary",
          disabled: !_ctx.allItemSelected,
          class: "mt-3",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menu_set_add_btn', { price: _ctx.totalPrice })), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }))
}