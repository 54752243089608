/* eslint camelcase: 0 no-useless-constructor:0 */

export class MenuItemModel {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly price: number,
    readonly priceFormatted: string,
    readonly details: string,
    readonly imageUrl: string,
    readonly priceBeforeDiscount?: number,
    readonly priceBeforeDiscountFormatted?: string
  ) { }
}

export class MenuSectionModel {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly content: MenuItemModel[]
  ) { }
}

export class MenuSetItem {
  constructor(
    readonly id: string,
    readonly productName: string,
    readonly extraPrice: number,
    readonly extraPriceFormatted: string
  ) { }
}

export class MenuSetCategory {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly maxSelection: number,
    readonly minSelection: number,
    readonly possibleItems: MenuSetItem[]
  ) { }
}

export class MenuSet {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly imageUrl: string,
    readonly initialPrice: number,
    readonly initialPriceFormatted: string,
    readonly categories: MenuSetCategory[]
  ) {
  }

  getTotalPrice(selectedItems: string[]): number {
    return this.getMenuSetItems(selectedItems)
      .reduce((sum, item) => sum + item.extraPrice, this.initialPrice)
  }

  getMenuSetItems(selectedItems: string[]): MenuSetItem[] {
    return this.categories
      .flatMap(cat => cat.possibleItems)
      .filter(item => selectedItems.indexOf(item.id) >= 0)
  }
}

export enum MenuDisplayStyle {
  COMPACT, COMPACT_WITHOUT_IMAGE, NORMAL
}

export class BusinessModel {
  constructor(
    readonly id: number,
    readonly slug: string,
    readonly title: string,
    readonly isOpened: boolean,
    readonly image: string,
    readonly locale: string,
    readonly description: string,
    readonly address: string,
    readonly addressUrl: string,
    readonly processingTimeMinutes: number,
    readonly askClientPhoneNumber: boolean,
    readonly displayEta: boolean,
    readonly menuDisplayStyle: MenuDisplayStyle,
    readonly requiresLoginToOrder: boolean
  ) { }
}

export class MenuModel {
  constructor(
    readonly id: number,
    readonly content: MenuSectionModel[],
    readonly menuSets: MenuSet[],
    readonly business: BusinessModel
  ) { }

  getItemById(id: string): MenuItemModel | null {
    for (const section of this.content) {
      for (const item of section.content) {
        if (item.id === id) {
          return item
        }
      }
    }
    return null
  }

  getMenuSetItem(itemId: string): MenuSet | null {
    return this.menuSets.find(it => it.id === itemId)
  }
}
