
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'

@Options({
  props: {
    item: MenuItemModel,
    onDismiss: Function,
    altImage: String
  }
})
export default class MenuItemDetailsView extends Vue {
  item!: MenuItemModel
  onDismiss: () => void
  altImage: string

  get cart(): Cart | null {
    return this.$store.state.cart
  }

  get imageUrl(): string {
    return this.item.imageUrl == null ? this.altImage : this.item.imageUrl
  }

  mounted(): void {
    const route = useRoute()
    if (this.$store.state.menu == null) {
      this.$store.dispatch('fetchMenu', route.params.slug)
    }
  }

  countOnCart(): number {
    return this.cart?.getItem(this.item.id)?.count || 0
  }

  onAddToCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('addToCart', new CartSingleItem(menuItem.id, 1))
  }

  onMinusFromCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('minusFromCart', menuItem.id)
  }
}
