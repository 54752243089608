export default {
  "page_home_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business"))])},
  "page_orders_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your orders"])},
  "page_login_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "page_signup_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "page_forgot_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "page_checkout_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business")), " - checkout"])},
  "page_order_details_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business")), " - your order"])},
  "basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "cart_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View basket"])},
  "cart_empty_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your basket is empty"])},
  "cart_go_to_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check my order"])},
  "menu_set_add_btn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add for ", _interpolate(_named("price"))])},
  "item_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this item"])},
  "section_menu_sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu sets"])},
  "account_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
  "account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
  "account_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "account_confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "account_update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
  "account_passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "account_passwords_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passwod has been updated"])},
  "account_reset_password_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent to you to reset your password"])},
  "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "account_no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
  "account_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an account already?"])},
  "error_invalid_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is invalid"])},
  "error_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
  "error_email_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is already in use"])},
  "error_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during signup."])},
  "error_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field required"])},
  "error_week_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week password"])},
  "error_invalid_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This phone is not valid"])},
  "error_field_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is too long"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "checkout_review_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Review your order from ", _interpolate(_named("name"))])},
  "checkout_submit_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't create your order"])},
  "checkout_input_details_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your details"])},
  "checkout_input_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "checkout_input_phone_number_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
  "checkout_input_comment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (optionnal)"])},
  "checkout_input_comment_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comments"])},
  "checkout_establishment_address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment address"])},
  "checkout_establishment_address_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerary"])},
  "checkout_submit_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place the order"])},
  "order_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place the order"])},
  "order_at_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order at"])},
  "order_canceled_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order is canceled, see you next time for another one."])},
  "order_done_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order is done, see you next time for another one."])},
  "order_received_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order received"])},
  "order_received_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order should be picked up soon."])},
  "order_picked_up_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order picked up"])},
  "order_picked_up_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " is processing your order."])},
  "order_picked_up_eta_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It should be ready around."])},
  "order_ready_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
  "order_ready_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order is waiting for you to be picked up."])},
  "order_status_received": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order <strong>#", _interpolate(_named("order_number")), "</strong> at ", _interpolate(_named("business")), " is received."])},
  "order_status_in_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order <strong>#", _interpolate(_named("order_number")), "</strong> at ", _interpolate(_named("business")), " is in progress."])},
  "order_status_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order <strong>#", _interpolate(_named("order_number")), "</strong> at ", _interpolate(_named("business")), " is ready."])},
  "order_status_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your order <strong>#", _interpolate(_named("order_number")), "</strong> at ", _interpolate(_named("business")), "."])},
  "my_oders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
  "estimated_waiting_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estimated waiting time: <strong>", _interpolate(_named("time")), "</strong>"])},
  "closed_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are closed at the moment"])},
  "error_invalid_credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or password is incorrect"])},
  "you_are_authentifed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your are authentifed"])},
  "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Something Went Wrong: Our Apologies"])},
  "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])}
}