import i18n from '@/i18n'
import { Order, OrderStatus } from '@/model/OrderCheckout'

export function getOrderStatus(order: Order): string {
  let key = ''
  switch (order.status) {
    case OrderStatus.received:
      key = 'order_status_received'
      break
    case OrderStatus.inProgress:
      key = 'order_status_in_progress'
      break
    case OrderStatus.readyToPickup:
      key = 'order_status_ready'
      break
    default:
      key = 'order_status_other'
  }
  const { t } = i18n.global
  return t(key, { order_number: order.number, business: order.business.title })
}
