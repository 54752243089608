import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-body-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-body-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_v_radio_group, {
    modelValue: _ctx.selectedItem,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
    class: "menu-set-single-choice-list-view"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuSetItems, (item) => {
        return _withDirectives((_openBlock(), _createBlock(_component_v_radio, {
          key: item.id,
          value: item,
          class: "justify-start"
        }, {
          label: _withCtx(() => [
            _createVNode(_component_v_row, {
              justify: "space-between",
              class: "w-100 pl-5 pr-5 font-weight-medium"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.productName), 1),
                (item.extraPrice > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "+" + _toDisplayString(item.extraPriceFormatted), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value"])), [
          [_directive_ripple]
        ])
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}