import { Cart } from '@/model/Cart'
import { BusinessModel } from '@/model/Menu'
import { Order, OrderCheckoutEvent as OrderCheckoutInputs } from '@/model/OrderCheckout'
import { userRepository } from './UserRepository'
import { getOrderDetails, getUserOrders, sendOrder } from './api/api'
import { cartItemsToDto } from './dto/Cart.dto'
import { OrderCheckoutDTO, mapToOrder } from './dto/OrderCheckout.dto'
import { CacheData } from './CacheData'

const ORDER_NAME_KEY = 'order_name'
const ORDER_PHONE_NUMBER_KEY = 'order_phone_number'
const TABLE_CACHE_DURATION = 60 * 60 * 2

export class CheckoutRepository {
  private cache = new CacheData<string>('checkout')

  sentTable(table: string, businessSlug: string) {
    this.cache.set(`table_${businessSlug}`, table, TABLE_CACHE_DURATION)
  }

  async sendOrder(order: OrderCheckoutInputs, business: BusinessModel, cart: Cart): Promise<Order> {
    this.storeCheckoutData(order)
    const userId = business.requiresLoginToOrder ? null : await userRepository.getOrGenerateUserId()
    let token: string = null
    if (business.requiresLoginToOrder) {
      token = await userRepository.getOrRefreshToken()
    }
    const table = this.cache.get(`table_${business.slug}`)
    const cartItemsDto = cartItemsToDto(cart.items)
    const dto = new OrderCheckoutDTO(cart.id, order.name || '-', cartItemsDto, order.phoneNumber, order.comments, userId, table)

    const resDto = await sendOrder(token, business.id, dto)
    return mapToOrder(resDto)
  }

  async getOrder(id: string): Promise<Order> {
    const dto = await getOrderDetails(id)
    const model = mapToOrder(dto)
    return model
  }

  async getUserOrders(onlyTodo: boolean, businessSlug: string): Promise<Order[]> {
    const userId = userRepository.getUserId()
    if (!userId) {
      return []
    }
    const dtoArray = await getUserOrders(businessSlug, onlyTodo)
    const model = dtoArray.map(dto => mapToOrder(dto))
    return model
  }

  getLastSavedOrderCheckout(): OrderCheckoutInputs | null {
    const orderName = localStorage.getItem(ORDER_NAME_KEY)
    const orderPhoneNumber = localStorage.getItem(ORDER_PHONE_NUMBER_KEY)
    if (orderName && orderPhoneNumber) {
      return new OrderCheckoutInputs(orderName, orderPhoneNumber)
    }
    return null
  }

  private storeCheckoutData(order: OrderCheckoutInputs) {
    localStorage.setItem(ORDER_NAME_KEY, order.name)
    if (order.phoneNumber) {
      localStorage.setItem(ORDER_PHONE_NUMBER_KEY, order.phoneNumber)
    }
  }
}

const checkoutRepository = new CheckoutRepository()
export default checkoutRepository
