import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-decoration":"line-through"} }
const _hoisted_2 = {
  key: 0,
  class: "mr-1 mt-2 justify-center",
  color: "grey-darken-4"
}
const _hoisted_3 = {
  class: "align-self-center mr-3 ml-3 text-subtitle-1",
  color: "blue-grey-darken-4"
}
const _hoisted_4 = {
  key: 1,
  class: "mr-1 mt-2 mb-1 justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "card-shadow card-padding pa-2 rounded-xl ma-3",
    color: "cardTheme",
    style: {"width":"230px"}
  }, {
    default: _withCtx(() => [
      (_ctx.showImage)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { align: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    width: "130",
                    height: "130",
                    cover: "",
                    src: _ctx.item.imageUrl,
                    class: "rounded-circle"
                  }, null, 8, ["src"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_v_card_title, { class: "pt-0 pb-0" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "text-left pt-1 pb-0 one-line" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.details), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_subtitle, { class: "pt-1 pb-0 font-weight-bold text-black text-subtitle-1" }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ highlight: _ctx.item.priceBeforeDiscountFormatted != null }, "mr-1"])
            }, _toDisplayString(_ctx.item.priceFormatted), 3),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.item.priceBeforeDiscountFormatted), 1)
          ]),
          _: 1
        }),
        (_ctx.countOnCart > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.countOnCart == 1)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    icon: "mdi-delete",
                    color: "blue-grey-darken-4",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onMinusFromCart()), ["stop"]))
                  }))
                : (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    icon: "mdi-minus",
                    color: "blue-grey-darken-4",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onMinusFromCart()), ["stop"]))
                  })),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.countOnCart), 1),
              _createVNode(_component_v_btn, {
                density: "default",
                icon: "mdi-plus",
                color: "blue-grey-darken-4",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onAddToCart()), ["stop"]))
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_v_btn, {
                "prepend-icon": "mdi-plus",
                class: "fab mr-1 mt-2 rounded-pill",
                color: "blue-grey-darken-4",
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onAddToCart()), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('item_add')), 1)
                ]),
                _: 1
              })
            ]))
      ])
    ]),
    _: 1
  }))
}