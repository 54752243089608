
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { Options, Vue } from 'vue-class-component'
import OrderListView from './OrderListView.vue'

@Options({
  props: {},
  components: { OrderListView }
})
export default class OngoingOrdersView extends Vue {
  displayOrdersDialog = false

  setDisplayOrdersDialog(show: boolean): void {
    this.displayOrdersDialog = show
  }

  get orders(): Order[] {
    return this.$store.state.ongoingOrders ?? []
  }

  mounted(): void {
    this.$store.dispatch('fetchOngoingOrders', this.$store.state.menu.business.slug)
  }

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
