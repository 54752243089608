import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { align: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "pl-7 mr-7 pt-12 justify-center rounded-xl" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_img, {
              alt: "Vue logo",
              src: require(`@/assets/img_not_found.png`),
              width: 150,
              height: 150
            }, null, 8, ["src"])
          ]),
          _createVNode(_component_v_card_title, { class: "ext-h5 text-error font-weight-bold pt-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-alert-circle",
                color: "error",
                class: "pr-4"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }),
          (_ctx.showTryAgain)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onTryAgain'))),
                class: "ma-7"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('try_again')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}