
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    orders: Array as PropType<Array<Order>>
  }
})
export default class OrderListView extends Vue {
  orders!: Order[]

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
