import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_MenuSetDetailsView = _resolveComponent("MenuSetDetailsView")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_col, {
    cols: "12",
    lg: "6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDetailsDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDetailsDialog) = $event)),
        class: "align-center",
        fullscreen: _ctx.showDialogAsFullScreen
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_card, _mergeProps({
            class: "rounded-xl pt-2 pb-2",
            color: "cardTheme",
            height: "100%"
          }, props), {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "d-flex justify-space-between" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "pt-0 pb-0" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.menuSet.title), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_subtitle, { class: "pt-0 pb-0 font-weight-bold text-black text-subtitle-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.menuSet.initialPriceFormatted), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, {
                        width: "150",
                        height: "100",
                        "aspect-ratio": "1/1",
                        cover: "",
                        src: _ctx.menuSet.imageUrl
                      }, null, 8, ["src"]),
                      _createVNode(_component_v_btn, {
                        density: "comfortable",
                        icon: "mdi-plus",
                        class: "z-index fab raised mr-1 mt-1",
                        position: "absolute",
                        color: "blue-grey-darken-4",
                        elevation: "9",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onAddToCart()), ["stop"]))
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_MenuSetDetailsView, {
            menuSet: _ctx.menuSet,
            cart: _ctx.cart,
            locale: _ctx.locale,
            class: "align-self-center",
            "max-width": "800",
            align: "center",
            onOnDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDetailsDialog = false))
          }, null, 8, ["menuSet", "cart", "locale"])
        ]),
        _: 1
      }, 8, ["modelValue", "fullscreen"])
    ]),
    _: 1
  }))
}