import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "d-flex justify-center mt-10 pb-10" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        width: "500",
        "min-height": 500,
        class: "rounded-xl"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "top-left")
            ]),
            _: 3
          }),
          _createVNode(_component_v_col, { class: "pa-4 ma-2 content-center-vetically" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}