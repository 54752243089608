import moment from 'moment'

export function formatDateTime(datetime: Date): string {
  return moment(datetime).format('LLL')
}

export function formatDuration(minutes: number): string {
  const duration = moment.duration(minutes, 'minute').humanize()
  return duration
}

export function formatDurationFromNow(datetime: Date): string {
  const now = moment(new Date())
  const diff = moment(now).diff(datetime)
  const duration = moment.duration(diff).humanize()
  return duration
}

export function formatTime(datetime: Date): string {
  return moment(datetime).format('LT')
}
