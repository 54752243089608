import { getFirebaseApp } from './firebase'
import { getAnalytics, logEvent } from 'firebase/analytics'

const analytics = getAnalytics(getFirebaseApp())

export function trackEvent(eventName: string, params: { [key: string]: any }) {
  logEvent(analytics, eventName, params)
}

export function trackScreen(screenName: string, screenClass, params: { [key: string]: any }) {
  logEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
    firebase_screen_class: screenClass,
    params
  })
}
