
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'
import { isEmailValid, isPasswordValid } from '@/form/rules'
import { useI18n } from 'vue-i18n'
import { userRepository } from '@/data/UserRepository'
import ConnexionLayout from '@/components/ConnexionLayout.vue'

@Options({
  components: { ConnexionLayout }
})
export default class LoginView extends Vue {
  emailInput = ''
  passwordInput = ''
  valid = false
  done = false
  passwordVisible = false
  isLoading = false
  errorMessage = ''

  emailRules() {
    const { t } = useI18n()
    if (!isEmailValid(this.emailInput)) {
      return [t('error_invalid_email')]
    }
    return [true]
  }

  passwordRules() {
    const { t } = useI18n()
    if (!isPasswordValid(this.passwordInput)) {
      return [t('error_field_required')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      this.errorMessage = ''
      const url = this.$route.query.redirect_url as string
      userRepository.login(this.emailInput, this.passwordInput)
        .then(() => {
          this.$store.dispatch('fetchUserName')
          if (url) {
            this.$router.replace(url)
          }
          this.done = true
        })
        .catch((e) => {
          console.log('Error login', e)
          this.errorMessage = this.$t('error_invalid_credential')
          this.isLoading = false
        })
    }
  }
}
