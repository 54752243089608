import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OrderListView = _resolveComponent("OrderListView")!
  const _component_v_bottom_sheet = _resolveComponent("v-bottom-sheet")!

  return (_ctx.orders.length > 0)
    ? (_openBlock(), _createBlock(_component_v_bottom_sheet, {
        key: 0,
        modelValue: _ctx.displayOrdersDialog,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayOrdersDialog) = $event)),
        inset: ""
      }, {
        activator: _withCtx(({ props }) => [
          (_ctx.orders.length == 1)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'order', params: { order_id: _ctx.orders[0].uuid } },
                custom: ""
              }, {
                default: _withCtx(({ navigate }) => [
                  _createVNode(_component_v_card, _mergeProps({
                    color: "primary",
                    class: "mt-2 rounded-xl",
                    onClick: navigate
                  }, props), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, { class: "text-left text-subtitle-1 font-weight-bold" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            innerHTML: _ctx.orderStatus(_ctx.orders[0])
                          }, null, 8, _hoisted_1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040, ["onClick"])
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_ctx.orders.length > 0)
              ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
                  key: 1,
                  color: "primary rounded-xl",
                  class: "mt-2",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDisplayOrdersDialog(true)))
                }, props), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, { class: "text-left text-subtitle-1 font-weight-bold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('order_count_ongoing_orders', { count: _ctx.orders.length })), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040))
              : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_OrderListView, { orders: _ctx.orders }, null, 8, ["orders"])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}