
declare const grecaptcha: any

export interface CaptchaResult {
  token: string
}

export function executeCaptcha(): Promise<CaptchaResult> {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      console.log('Token', process.env)
      grecaptcha.execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, { action: 'submit' })
        .then((token: string) => {
          resolve({ token: token })
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  })
}
