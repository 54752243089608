
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    message: String,
    showTryAgain: Boolean
  }
})
export default class ErrorStateView extends Vue {
  message: string
  showTryAgain: boolean
}

