
import MenuItemView from './menu/MenuItemView.vue'
import { Cart } from '@/model/Cart'
import { MenuModel, MenuSetItem } from '@/model/Menu'
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import MenuSetView from './menu/MenuSetView.vue'

@Options({
  props: {
    menu: MenuModel,
    cart: Cart,
    selectedSectionId: String,
    onSectionIntersection: Function as PropType<(sectionId: string) => void>
  },
  components: { MenuItemView, MenuSetView }
})
export default class MenuListView extends Vue {
  menu!: MenuModel
  cart?: Cart
  onSectionIntersection?: (sectionId: string) => void
  selectedSectionId: string
  scrollObserver: IntersectionObserver

  mounted(): void {
    this.scrollObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (this.onSectionIntersection) {
              this.onSectionIntersection(entry.target.id)
            }
          }
        })
      },
      {
        threshold: 0.5
      }
    )

    const container = this.$refs.menu_container as HTMLElement
    for (let i = 0; i < container.children.length; i++) {
      this.scrollObserver.observe(container.children.item(i))
    }
  }

  beforeUnmount() {
    this.scrollObserver.disconnect()
  }

  scrollToSection(sectionId: string) {
    const sectionContent = this.$refs[sectionId] as Array<HTMLElement>
    if (sectionContent) {
      sectionContent[0].scrollIntoView({ behavior: 'smooth' })
    }
  }
}
